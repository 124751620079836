import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ToolbarModule from "@/store/modules/ToolbarModule";
import ReportModule from "@/store/modules/ReportModule";
import ParamsModule from "@/store/modules/ParamsModule";
import SecondaryParamsModule from "@/store/modules/SecondaryParamsModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ToolbarModule,
    ReportModule,
    ParamsModule,
    SecondaryParamsModule
  },
});

export default store;
