import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

// export interface User {
//   name: string;
//   surname: string;
//   email: string;
//   password: string;
//   api_token: string;
// }

export interface User {
  user_id: string;
  user_name: string;
  user_avatar: string;
  gender: string;
  birthday: string;
  email: string;
  mobile: string;
  last_login_time: string;
  //user_password: string;
  is_real_name_auth: string;
  real_name: string;
  id_card_no: string;
  token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  //user = {} as User;
  user = UserService.getUser() as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    UserService.saveUser(this.user);
    this.errors = [];
    JwtService.saveToken(this.user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.user_password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN_AUTH_CODE](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("MemberAuth/SignInAuthCode", credentials)
        .then(({ data }) => {
          if (data.success == true) {
            this.context.commit(Mutations.SET_AUTH, data.data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, [data.message]);
            reject();
          }
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, [error.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGIN_FACE](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("MemberAuth/SignInFace", credentials)
        .then(({ data }) => {
          if (data.success == true) {
            this.context.commit(Mutations.SET_AUTH, data.data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, [data.message]);
            reject();
          }
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, [error.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("MemberAuth/SignIn", credentials)
        .then(({ data }) => {
          if (data.success == true) {
            this.context.commit(Mutations.SET_AUTH, data.data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, [data.message]);
            reject();
          }
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, [error.message]);
          reject();
        });
    });
  }

  // @Action
  // [Actions.LOGIN](credentials) {
  //   const params = {
  //     params: {
  //       ...credentials,
  //     },
  //   };
  //   return ApiService.query("login", params)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_AUTH, data);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.SIGN_OUT]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("MemberAuth/SignOut", {} as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, [data.message]);
            reject();
          }
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, [error.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const params = {
        params: {
          api_token: JwtService.getToken(),
        },
      };
      ApiService.query("verify_token", params as AxiosRequestConfig)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    this.context.commit(Mutations.SET_AUTH, payload);
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
