import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { initCurrentPageToolbar } from "@/core/helpers/toolbar";
import Bus from "@/common/ts/Bus";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Account.vue"),
        children: [
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/profile/Settings.vue"),
          },
          {
            path: "security",
            name: "account-security",
            component: () => import("@/views/profile/Security.vue"),
          },
          {
            path: "certificationEdit",
            name: "account-certificationEdit",
            component: () => import("@/views/profile/certification/CertificationEdit.vue"),
          },
          {
            path: "certificationView",
            name: "account-certificationView",
            component: () => import("@/views/profile/certification/CertificationView.vue"),
          },
        ],
      },
      {
        path: "/exam",
        name: "exam",
        component: () => import("@/views/exam/Exam.vue"),
      },
      {
        path: "/exam/ready",
        name: "exam-ready",
        component: () => import("@/views/exam/ExamReady.vue"),
      },
      {
        path: "/exam/start",
        name: "exam-start",
        component: () => import("@/views/exam/ExamStart.vue"),
      },
      {
        path: "/course",
        name: "course",
        component: () => import("@/views/course/Course.vue"),
      },
      {
        path: "/course/detail",
        name: "course-detail",
        //component: () => import("@/views/course/CourseDetail.vue"),
        component: () => import("@/views/class/course/CourseDetail.vue"),
      },
      {
        path: "/exercise",
        name: "exercise",
        component: () => import("@/views/exercise/ExerciseList.vue"),
      },
      {
        path: "/exercise/ready",
        name: "exercise-ready",
        component: () => import("@/views/exercise/ExerciseReady.vue"),
      },
      {
        path: "/exercise/start",
        name: "exercise-start",
        component: () => import("@/views/exercise/ExerciseStart.vue"),
      },
      {
        path: "/exercise/search",
        name: "exercise-search",
        component: () => import("@/views/exercise/search/ExerciseSearch.vue"),
      },
      {
        path: "/exercise/question",
        name: "exercise-question",
        component: () => import("@/views/exercise/search/QuestionDetail.vue"),
      },
      {
        path: "/mock/ready",
        name: "mock-ready",
        component: () => import("@/views/mock/MockReady.vue"),
      },
      {
        path: "/mock/start",
        name: "mock-start",
        component: () => import("@/views/mock/MockStart.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("@/views/news/NewsList.vue"),
      },
      {
        path: "/news/detail",
        name: "news-detail",
        component: () => import("@/views/news/NewsDetail.vue"),
      },
      {
        path: "/class",
        name: "class",
        component: () => import("@/views/class/Class.vue"),
      },
      {
        path: "/class/manage",
        name: "class-manage",
        component: () => import("@/views/class/ClassManage.vue")
      },

      // {
      //   path: "/course/detail",
      //   name: "course-detail",
      //   component: () => import("@/views/course/CourseDetail.vue"),
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/sign-in-auth-code",
        name: "sign-in-auth-code",
        component: () => import("@/views/auth/SignInAuthCode.vue"),
      },
      {
        path: "/sign-in-face",
        name: "sign-in-face",
        component: () => import("@/views/auth/SignInFace.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/auth/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const offBus = () => {
  Bus.$off("handleBack");
};

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH); //delete by david

  initCurrentPageToolbar(); //add by david

  offBus(); //add by david

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
