import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

/**
 * Sets current page toolbar
 * @param {string} button Current page button name
 */
export const setCurrentPageButton = (action: string, show: boolean): void => {
  store.dispatch(Actions.SET_BUTTON_ACTION, { action: action, show: show });
};

/**
 * Sets current page toolbar
 * @param {string} button Current page button name
 */
export const initCurrentPageToolbar = (): void => {
  store.dispatch(Actions.INIT_TOOLBAR_ACTION);
};
