enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  //begin::add by david
  LOGIN_AUTH_CODE = "login_auth_code",
  LOGIN_FACE = "login_face",
  INIT_TOOLBAR_ACTION = "initToolbarAction",
  SET_BUTTON_ACTION = "setButtonAction",
  SET_REPORT_ACTION = "setReportAction",
  SET_REPORT_CLASS = "setReportClassAction",
  SET_REPORT_COURSE = "setReportCourseAction",
  SET_REPORT_EXAM = "setReportExamAction",
  SET_PARAMS_ACTION = "setParamsAction",
  REMOVE_PARAMS_ACTION = "removeParamsAction",
  SET_SECONDARY_PARAMS_ACTION = "setSecondaryParamsAction",
  REMOVE_SECONDARY_PARAMS_ACTION = "removeSecondaryParamsAction",
  SIGN_OUT = "signout"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  //begin::add by david
  INIT_TOOLBAR_MUTATION = "initToolbarMutation",
  SET_BUTTON_MUTATION = "setButtonMutation",
  SET_REPORT_MUTATION = "setReportMutation",
  SET_REPORT_CLASS = "setReportClassMutation",
  SET_REPORT_COURSE = "setReportCourseMutation",
  SET_REPORT_EXAM = "setReportExamMutation",
  SET_PARAMS_MUTATION = "setParamsMutation",
  REMOVE_PARAMS_MUTATION = "removeParamsMutation",
  SET_SECONDARY_PARAMS_MUTATION = "setSecondaryParamsMutation",
  REMOVE_SECONDARY_PARAMS_MUTATION = "removeSecondaryParamsMutation",
  SIGN_OUT = "signout"
}

export { Actions, Mutations };
