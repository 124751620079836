import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Report {
  class_num: number,
  course_num: number,
  exam_num: number
}

export interface ReportAuthInfo {
  report: Report
}

@Module
export default class ReportModule extends VuexModule implements ReportAuthInfo {
  report = {} as Report

  get currentReport(): Report{
    return this.report;
  }

  @Mutation
  [Mutations.SET_REPORT_MUTATION](report) {
    this.report = report;
  }

  @Action
  [Actions.SET_REPORT_ACTION](payload) {
    this.context.commit(Mutations.SET_REPORT_MUTATION, payload);
  }

  @Mutation
  [Mutations.SET_REPORT_CLASS](num) {
    this.report.class_num = num;
  }

  @Action
  [Actions.SET_REPORT_CLASS](payload) {
    this.context.commit(Mutations.SET_REPORT_CLASS, payload);
  }

  @Mutation
  [Mutations.SET_REPORT_COURSE](num) {
    this.report.course_num = num;
  }

  @Action
  [Actions.SET_REPORT_COURSE](payload) {
    this.context.commit(Mutations.SET_REPORT_COURSE, payload);
  }
  
  @Mutation
  [Mutations.SET_REPORT_EXAM](num) {
    this.report.exam_num = num;
  }

  @Action
  [Actions.SET_REPORT_EXAM](payload) {
    this.context.commit(Mutations.SET_REPORT_EXAM, payload);
  }
}
