import { Params } from "@/store/modules/ParamsModule";

const ID_PARAMS_KEY = "id_params" as string;

/**
 * @description get token form localStorage
 */
export const getParams = (): Params | null => {
  const val = window.sessionStorage.getItem(ID_PARAMS_KEY);
  return val ? JSON.parse(val) : {};
};

/**
 * @description save user into localStorage
 * @param token: string
 */
export const saveParams = (params: Params): void => {
  window.sessionStorage.setItem(ID_PARAMS_KEY, JSON.stringify(params));
};

/**
 * @description remove user form localStorage
 */
export const destroyParams = (): void => {
  window.sessionStorage.removeItem(ID_PARAMS_KEY);
};

export default { getParams, saveParams, destroyParams };
