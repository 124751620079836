import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import SecondaryParamsService from "@/core/services/SecondaryParamsService";

export interface Params {
  row: unknown,
  path: string
}

export interface ParamsAuthInfo {
  params: Params
}

@Module
export default class ParamsModule extends VuexModule implements ParamsAuthInfo {
  // params = {} as Params
  params = SecondaryParamsService.getParams() as Params;

  get currentSecondaryParams(): Params{
    return this.params;
  }

  @Mutation
  [Mutations.SET_SECONDARY_PARAMS_MUTATION](params) {
    this.params = params;
    SecondaryParamsService.saveParams(this.params);
  }

  @Action
  [Actions.SET_SECONDARY_PARAMS_ACTION](payload) {
    this.context.commit(Mutations.SET_SECONDARY_PARAMS_MUTATION, payload);
  }

  @Mutation
  [Mutations.REMOVE_SECONDARY_PARAMS_MUTATION]() {
    this.params = {} as Params;
    SecondaryParamsService.destroyParams();
  }

  @Action
  [Actions.REMOVE_SECONDARY_PARAMS_ACTION]() {
    this.context.commit(Mutations.REMOVE_SECONDARY_PARAMS_MUTATION);
  }
}
