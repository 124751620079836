import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Toolbar {
  back: boolean;
}

interface ToolbarAuthInfo {
  toolbar: Toolbar;
}

@Module
export default class ToolbarModule
  extends VuexModule
  implements ToolbarAuthInfo
{
  toolbar = {} as Toolbar;

  /**
   * Get toolbar object for current page
   * @returns object
   */
  get getToolbar(): Toolbar {
    return this.toolbar;
  }

  @Mutation
  [Mutations.INIT_TOOLBAR_MUTATION]() {
    this.toolbar = {} as Toolbar;
  }

  @Action
  [Actions.INIT_TOOLBAR_ACTION]() {
    this.context.commit(Mutations.INIT_TOOLBAR_MUTATION);
  }

  @Mutation
  [Mutations.SET_BUTTON_MUTATION](payload) {
    switch (payload.action) {
      case "back":
        this.toolbar.back = payload.show;
        break;
    }
  }

  @Action
  [Actions.SET_BUTTON_ACTION](payload) {
    this.context.commit(Mutations.SET_BUTTON_MUTATION, payload);
  }
}
