import { User } from "@/store/modules/AuthModule";

const ID_USER_KEY = "id_user" as string;

/**
 * @description get token form localStorage
 */
export const getUser = (): User | null => {
  const val = window.localStorage.getItem(ID_USER_KEY);
  return val ? JSON.parse(val) : {};
};

/**
 * @description save user into localStorage
 * @param token: string
 */
export const saveUser = (user: User): void => {
  window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user));
};

/**
 * @description remove user form localStorage
 */
export const destroyUser = (): void => {
  window.localStorage.removeItem(ID_USER_KEY);
};

export default { getUser, saveUser, destroyUser };
