import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ParamsService from "@/core/services/ParamsService";

export interface Params {
  row: unknown,
  path: string
}

export interface ParamsAuthInfo {
  params: Params
}

@Module
export default class ParamsModule extends VuexModule implements ParamsAuthInfo {
  // params = {} as Params
  params = ParamsService.getParams() as Params;

  get currentParams(): Params{
    return this.params;
  }

  @Mutation
  [Mutations.SET_PARAMS_MUTATION](params) {
    this.params = params;
    ParamsService.saveParams(this.params);
  }

  @Action
  [Actions.SET_PARAMS_ACTION](payload) {
    this.context.commit(Mutations.SET_PARAMS_MUTATION, payload);
  }

  @Mutation
  [Mutations.REMOVE_PARAMS_MUTATION]() {
    this.params = {} as Params;
    ParamsService.destroyParams();
  }

  @Action
  [Actions.REMOVE_PARAMS_ACTION]() {
    this.context.commit(Mutations.REMOVE_PARAMS_MUTATION);
  }
}
